import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/style/global.css'
import './assets/iconfont/iconfont.css'
import utils from './utils/index'
import axios from 'axios';
import store from "./store/index.js"
Vue.prototype.$store = store
Vue.prototype.$axios = axios;

Vue.config.productionTip = false
Vue.prototype.$utils = utils

Vue.use(ElementUI)
const baseSize = 16
function setRem () {
    const scale = document.documentElement.clientWidth / 1920
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
