import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loginPacman',
    component: () => import(/* webpackChunkName: "loginPacman" */ '../views/loginPacman.vue'),
  },
  {
    path: '/gamePayment',
    name: 'gamePayment',
    component: () => import(/* webpackChunkName: "gamePayment" */ '../views/gamePayment/Index.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "aboutUs" */ '../views/aboutUs.vue')
  },
  {
    path: '/privacyStatement',
    name: 'privacyStatement',
    component: () => import(/* webpackChunkName: "privacyStatement" */ '../views/privacyStatement.vue')
  },
  {
    path: '/cookieStatement',
    name: 'cookieStatement',
    component: () => import(/* webpackChunkName: "cookieStatement" */ '../views/cookieStatement.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue')
  },
  {
    path: '/contact',
    name: 'faq',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
  },
  {
    path: '/termsofUse',
    name: 'termsofUse',
    component: () => import(/* webpackChunkName: "termsofUse" */ '../views/termsofUse.vue')
  },
]

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
