export default{
	state:{
		// token:null,
		// //用户信息
		// userInfo:{}
		numGame: sessionStorage.getItem('numGame') ? sessionStorage.getItem('numGame') : '1',
        cartListNum: JSON.parse(sessionStorage.getItem('cartList')) ?  JSON.parse(sessionStorage.getItem('cartList')).length : ''
	},
	getters:{},
	mutations:{
        getCartListNum (state) {
            state.cartListNum = JSON.parse(sessionStorage.getItem('cartList')) ?  JSON.parse(sessionStorage.getItem('cartList')).length : ''
        },
        getnumGame (state) {
            state.numGame = sessionStorage.getItem('numGame')
        }
	},
	actions:{
	},
	
}